<template>
  <div :style="[layoutStyle, bgStyle]" :class="[layoutClass, bgClass]"></div>
</template>

<script>
import layoutMixin from "@/mixins/layoutMixin";

export default {
  name: "RectangleElement",
  mixins: [layoutMixin],
  props: {
    //
  },
  data() {
    return {
      //
    };
  },
  created() {
    //
  },
  computed: {
    //
  },
};
</script>
